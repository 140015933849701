<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>Podcast</v-card-title>
        <v-card-title>
          <v-row no-gutter>
            <v-col cols="12" sm="6" md="8">
              <v-form ref="form" v-model="el.validForm" lazy-validation>
                <div>
                  <v-switch v-model="podcast.enabled" label=" Enabled" outlined />
                </div>
                <div>
                  <v-dialog
                    ref="publishDateDialog"
                    v-model="el.publishDateDialog"
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="podcast.date"
                        label="Publish date"
                        v-on="on"
                        :rules="[rule.required]"
                        outlined
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="podcast.date"
                      @input="el.publishDateDialog = false"
                      scrollable
                    >
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div>
                  <v-text-field
                    v-model="podcast.site_title"
                    type="text"
                    label="Site title"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="podcast.meta_description"
                    type="text"
                    label="Meta description"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="podcast.meta_keywords"
                    type="text"
                    label="Meta keywords"
                    outlined
                  />
                </div>
                <div class="mb-5">
                  <v-tabs v-model="el.tabs" grow>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Dutch</v-tab>
                    <v-tab>English</v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-items v-model="el.tabs">
                    <v-tab-item :eager="true">
                      <v-card class="pt-4" flat>
                        <div>
                          <v-text-field
                            v-model="podcast.title"
                            type="text"
                            label="Title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="podcast.title_short"
                            type="text"
                            label="Short title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <html-editor
                            v-model="podcast.body"
                            placeholder="Podcast body"
                            :className="el.bodyError ? 'podcast-body-error' : ''"
                          ></html-editor>
                          <v-slide-y-transition>
                            <div
                              v-if="el.bodyError"
                              class="podcast-body-error--detail red--text text--lighten-6 mx-3"
                            >
                              Required.
                            </div>
                          </v-slide-y-transition>
                        </div>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item :eager="true">
                      <v-card class="pt-4" flat>
                        <div>
                          <v-text-field
                            v-model="podcast.title_en"
                            type="text"
                            label="Title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="podcast.title_short_en"
                            type="text"
                            label="Short title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <html-editor
                            v-model="podcast.body_en"
                            placeholder="Podcast body"
                            :className="el.bodyError ? 'podcast-body-error' : ''"
                          />
                          <v-slide-y-transition>
                            <div
                              v-if="el.bodyEnError"
                              class="podcast-body-error--detail red--text text--lighten-6 mx-3"
                            >
                              Required.
                            </div>
                          </v-slide-y-transition>
                        </div>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </div>

                <v-divider></v-divider>

                <div class="mt-4">
                  <p class="subtitle-1 font-weight-bold">Image</p>
                  <v-row>
                    <v-col>
                      <v-img
                        :src="el.imagePreview"
                        class="mx-auto mb-3"
                        aspect-ratio="1.6"
                      ></v-img>
                      <v-file-input
                        v-model="podcast.image"
                        label="Upload image"
                        accept="image/png, image/jpeg, image/webp"
                        prepend-icon="mdi-camera-outline"
                        truncate-length="10"
                        :rules="isCreate ? [rule.required] : []"
                        show-size
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                    <v-col>
                      <template v-if="!isCreate">
                        <v-btn
                          @click="el.thumbnailDialog = true"
                          class="mb-4"
                          elevation="2"
                          block
                        >
                          Preview thumbnail
                        </v-btn>
                        <v-btn
                          @click="el.mainThumbnailDialog = true"
                          elevation="2"
                          block
                          >Preview main image</v-btn
                        >
                      </template>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-col>
            <v-col cols="6" md="4">
              <v-btn
                class="mb-4"
                block
                elevation="2"
                :loading="getPodcastPreviewLoading"
                @click="showPreviewPodcast(selectedLang)"
              >
                Preview podcast page
              </v-btn>
              <v-btn
                @click="save"
                :loading="getPodcastsLoading"
                color="primary"
                elevation="2"
                block
              >
                {{ isCreate ? "Save" : "Update" }} podcast
              </v-btn>
              <div v-if="lastUpdated" class="caption mt-2">
                Last updated: {{ lastUpdated }}
              </div>
            </v-col>
          </v-row>
        </v-card-title>
      </base-card>

      <template-preview
        v-if="preview.show"
        :selected-lang="selectedLang"
        v-model="preview.show"
        :src="preview.src"
        @language-select="showPreviewPodcast"
      ></template-preview>
      <Lightbox v-model="el.thumbnailDialog" :src="thumbnailSrc"></Lightbox>
      <Lightbox
        v-model="el.mainThumbnailDialog"
        :src="mainThumbnailSrc"
      ></Lightbox>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import rule from "@/shared/rules";
import _ from "lodash";
import moment from "moment";
import store from "@/store";
import Lightbox from "@/components/Lightbox";
import TemplatePreview from "@/components/TemplatePreview";
import HtmlEditor from "@/components/HtmlEditor";
import { LANGUAGE, DEFAULT_LANGUAGE } from "@/shared/constants";

export default {
  metaInfo: {
    title: "Podcast"
  },
  components: { HtmlEditor, Lightbox, TemplatePreview },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch("setModels", { model: "tag" });
      next();
    } catch (error) {
      console.error(error);
    }
  },
  data() {
    return {
      rule,
      id: _.get(this.$route, "params.id"),
      podcast: {
        enabled: false,
        date: moment().format("YYYY-MM-DD"),
        title: null,
        title_en: null,
        title_short: null,
        title_short_en: null,
        body: null,
        body_en: null,
        image: null,
        site_title: null,
        meta_keywords: null,
        meta_description: null
      },
      el: {
        validForm: false,
        publishDate: false,
        tabs: null,
        imageTemp: null,
        imagePreview: "https://i.stack.imgur.com/y9DpT.jpg",
        thumbnailDialog: false,
        mainThumbnailDialog: false,
        bodyError: false,
        bodyEnError: false
      },
      preview: {
        show: false,
        src: null
      },
      selectedLang: DEFAULT_LANGUAGE,
      languages: LANGUAGE
    };
  },
  mounted() {
    if (!this.isCreate) {
      const podcast = _.cloneDeep(store.getters.getPodcast(this.id));
      _.set(this.el, "imagePreview", this.getPreview(_.get(podcast, "image")));
      _.set(this.el, "imageTemp", _.get(podcast, "image"));
      _.set(podcast, "image", null);
      this.podcast = podcast;
    }
  },
  methods: {
    ...mapActions(["createPodcast", "updatePodcast", "previewPodcast"]),
    getPayload() {
      const payload = new FormData();
      _.forOwn(this.podcast, (value, key) => {
        let val = value;

        if (key === "enabled") {
          val = key === "enabled" ? Number(value) : value;
        }

        payload.append(key, val === null ? "" : val);
      });

      if (!this.isCreate) {
        _.each(["id", "created_at", "updated_at"], key => payload.delete(key));
      }

      return payload;
    },
    async showPreviewPodcast(lang) {
      this.selectedLang = lang;
      this.validatePodcastBody();

      if (!this.$refs.form.validate() || !this.validPodcastBody) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const payload = this.getPayload();
      this.preview.src = await this.previewPodcast({ id: this.id, payload, lang });
      this.preview.show = true;
    },
    save() {
      this.validatePodcastBody();

      if (!this.$refs.form.validate() || !this.validPodcastBody) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const payload = this.getPayload();
      this.isCreate
        ? this.createPodcast(payload)
        : this.updatePodcast({ id: this.id, payload });
    },
    validatePodcastBody() {
      this.el.bodyError = !this.htmlToText(this.podcast.body || "");
      this.el.bodyEnError = !this.htmlToText(this.podcast.body_en || "");
    },
    htmlToText(html) {
      return html.replace(/<[^>]*>?/gm, "");
    },
    getPreview(image) {
      return image
        ? `${process.env.VUE_APP_API_URL}/commands/thumbnail?resource=${image}&width=150&height=150`
        : "https://i.stack.imgur.com/y9DpT.jpg";
    }
  },
  computed: {
    ...mapGetters(["getModels", "getPodcastsLoading", "getPodcastPreviewLoading"]),
    isCreate() {
      return _.get(this.$route, "meta.kind") === "create";
    },
    validPodcastBody() {
      return !this.el.bodyError && !this.el.bodyEnError;
    },
    lastUpdated() {
      if (this.isCreate) {
        return null;
      }

      return moment(_.get(this.podcast, "updated_at")).format("YYYY-MM-DD HH:mm");
    },
    thumbnailSrc() {
      return this.el.imageTemp
        ? `${process.env.VUE_APP_API_URL}/commands/thumbnail?resource=${this.el.imageTemp}`
        : null;
    },
    mainThumbnailSrc() {
      return this.el.imageTemp
        ? `${process.env.VUE_APP_API_URL}/commands/thumbnail?resource=${this.el.imageTemp}&width=800&height=800`
        : null;
    }
  }
};
</script>
<style lang="scss" scoped>
.podcast-body-error {
  border: 2px solid #ff5252;
  border-radius: 4px;
}

.podcast-body-error--detail {
  font-size: 0.75rem;
}
</style>
