<template>
    <div>
      <v-progress-circular indeterminate
        color="primary"
        size="150"> Loading Preview </v-progress-circular>
      <v-dialog v-model="show"
        transition="dialog-bottom-transition"
        content-class="template-preview-dialog">
        <div class="template-preview-toolbar">
          <v-btn v-for="language in languages" :key="language"
            :color="language === selectedLang ? 'primary' : 'light'"
            @click="onLanguageSelect(language)"
            class="template-preview-toolbar-btn" >
            {{ language }}
          </v-btn>
        </div>
        <v-btn color="primary" class="template-preview-close-btn" @click="show = false">
          Close Preview
        </v-btn>
        <v-card class="d-flex justify-center align-center">
          <div class="framecover"></div>
          <iframe class="template-preview-iframe" :src="src" frameborder="0"></iframe>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import _ from "lodash";
import { LANGUAGE, DEFAULT_LANGUAGE } from "@/shared/constants";


export default {
  name: 'template-preview',
  props: {
    src: {
      type: String,
      default: '',
    },
    selectedLang: {
      type: String,
      default: DEFAULT_LANGUAGE,
    },
    value: {
      type: Boolean,
      default: '',
    },
  },
  mounted() {
    localStorage.setItem('activeLang', this.selectedLang);
  },
  data() {
    return {
      languages: LANGUAGE
    }
  },
  methods: {
    onLanguageSelect(language) {
      localStorage.setItem('activeLang', language);
      this.$emit('language-select', language);
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
<style lang="scss">
.template-preview-dialog {
  overflow: hidden !important;
  max-height: 95% !important;

  .template-preview-toolbar {
    .template-preview-toolbar-btn {
      margin: 0px 5px 5px 5px;
    }
  }

  .template-preview-close-btn {
    position: absolute;
    z-index: 12;
    bottom: 2px;
    left: 40%;
    width: 20%;
  }

  .framecover {
    width: 99%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
  }

  .template-preview-iframe {
    width: 100%;
    height: 100vh;
    a {
      pointer-events: none;
    }
  }
}
</style>
